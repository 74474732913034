<!-- 报名统计 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getBmstatisc,
    refreshBmstatisc,
  } from "@/api/common/public.js"
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,

    },
    data() {
      return {
        pageUrl: "",
        title2: "",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "基础大类",
            active: true
          }
        ],
        tableList: [],
        selectList: [{
            name: "报名",
            val: "BM",

          },
          {
            name: "报考单位",
            val: "DW",
          },
          {
            name: "报考职位",
            val: "ZW",
          }
        ],
        columns: [{
            name: "名额",
            val: "me",
          },
          {
            name: "缴费比例",
            val: "jfbl",
          },
          {
            name: "报名人数",
            val: "bmrs",
          },
          {
            name: "缴费人数",
            val: "jfrs",
          },
          {
            name: "退费人数",
            val: "tfrs",
          },
          {
            name: "未审核人数",
            val: "dshrs",
          },
          {
            name: "已通过审核人数",
            val: "shtgrs",
          },
          {
            name: "未通过审核人数",
            val: "shwtgrs",
          },
          {
            name: "减免通过人数",
            val: "jmtgrs",
          }

        ],
        checkForm: [],
        showmodal: false,
        isIndeterminate: false,
        newTjfw: "",
        ksmc: "",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          tjfw: "BM",
          ksbmbh: ""
        }
      };
    },
    methods: {
      getList() {
        getBmstatisc(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })

      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      searchClick() {
        this.pageData.pageNum = 1
        if (this.pageData.tjfw) {
          this.getList()
          this.newTjfw = this.pageData.tjfw

        } else {
          this.$message({
            type: 'warning',
            message: '统计方式不能为空！'
          });
        }
      },

      show() {
        this.checkForm = []
        this.isIndeterminate = false
        this.showmodal = true
      },
      handleCheckAllChange(e) {
        this.isIndeterminate = e
        this.checkForm = e ? this.columns.map(item => {
          return item.val
        }) : []


      },
      // 刷新
      refresh() {
        let newData = {
          ksbmbh: this.pageData.ksbmbh,
          tjfw: this.pageData.tjfw,
        }
        this.$confirm('刷新数据可能会消耗大量时间, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refreshBmstatisc(newData).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据正在刷新，请耐心等待'
              });
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消刷新'
          });
        });

      },
      // 下载
      downLoad() {
        let keyList = this.checkForm.join(",")
        let subData = {
          ksbmbh: this.pageData.ksbmbh,
          tjfw: this.pageData.tjfw,
          columns: keyList
        }

        let url = this.API_URL + "/yethan/register/statistical/downStatistical"
        let param = ""
        for (let i in subData) {
          param += i + "=" + subData[i] + "&"
        }
        if (param) {
          param = "?" + param.slice(0, -1)
        }
        let ytoken = '&ytoken=' + this.accessToken
        window.open(url + param + ytoken, '_blank')
        this.showmodal = false
      }
    },
    mounted() {
      this.getList()
    },
    created() {
      this.pageData.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ""
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ""
    }

  };
</script>

<template>
  <Layout>
    <CheckHeader :items="items" :title2="ksmc" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearable style="width: 10%;" placeholder="统计方式"
                  v-model="pageData.tjfw" size="small">
                  <el-option :value="item.val" v-for="item in selectList" :key="item.val" :label="item.name">
                    {{item.name}}
                  </el-option>
                </el-select>
                <input placeholder="输入报考单位" v-show="pageData.tjfw=='DW'||pageData.tjfw=='ZW'" v-model="pageData.bkdw"
                  class="h30 form-control border-blue mr-2 w-10" maxlength="50" />
                <input placeholder="职位编码,多个编码以英文逗号分割" v-show="pageData.tjfw=='ZW'" v-model="pageData.zwbms"
                  class="h30 form-control border-blue mr-2 w-15" maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2 w-xs" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询 </button>
                <b-button variant="outline-info" class="flexList mr-2 condition" @click="refresh()">刷新统计数据</b-button>
                <button type="button" class="btn btn-info h30 flexList mr-2 w-xs" @click="show()">下载统计数据 </button>
              </div>



            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 10%;" v-if="newTjfw=='DW'||newTjfw=='ZW'">报考单位</th>
                    <th style="width: 8%;" v-if="newTjfw=='ZW'">报考职位</th>
                    <th style="width: 5%;" v-if="newTjfw=='ZW'">报考职位代码</th>
                    <th style="width: 3%;">名额</th>
                    <th style="width: 5%;">缴费比例</th>

                    <th style="width: 8%;">报名人数</th>
                    <th style="width: 8%;">缴费人数</th>
                    <th style="width: 8%;">退费人数</th>
                    <th style="width: 8%;">未审核人数</th>
                    <th style="width: 8%;">已通过审核人数</th>
                    <th style="width: 8%;">未通过审核人数</th>
                    <th style="width: 8%;">减免通过人数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td v-if="newTjfw=='DW'||newTjfw=='ZW'">
                      {{obj.bkdw}}
                    </td>
                    <td v-if="newTjfw=='ZW'">
                      {{obj.zwmc}}
                    </td>
                    <td v-if="newTjfw=='ZW'">
                      {{obj.zwbm}}
                    </td>
                    <td>{{obj.me}}</td>
                    <td>
                      {{obj.jfbl}}
                    </td>

                    <td> {{obj.bmrs}}</td>
                    <td>{{obj.jfrs}}</td>
                    <td>{{obj.tfrs}}</td>
                    <td>{{obj.dshrs}}</td>
                    <td>{{obj.shtgrs}}</td>
                    <td>{{obj.shwtgrs}}</td>
                    <td>{{obj.jmtgrs}}</td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal v-model="showmodal" centered title="下载统计数据" title-class="font-18" hide-footer>
      <div>
        <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="white-space:break-spaces ;line-height: 30px; " class="flexList mr-2">
          <el-checkbox-group v-model="checkForm">
            <el-checkbox v-for="(item,index) in columns" :key="index" :value="item.val" :label="item.val">
              {{item.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="downLoad()">下载</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->

  </Layout>
</template>
